import React from 'react'
import PropTypes from 'prop-types'
import { PageBreadcrumb, ProductList, BrandSKUListHeader } from '../..'

const BrandItemPage = ({ brand, products, attributes, attribute_groups, lang, navigation }) => {
  return (
    <div className="product-list-4 product-list-breadcrumbs">
      <PageBreadcrumb
        lang={lang}
        current={{ title_nl: brand.name, title_en: brand.name, ...brand }}
        items={[ 'homepage', 'brands' ]}
        />

      <ProductList
        title={brand.name}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        lang={lang}
        navigation={navigation}
        keyPrefix="brand"
        header={<BrandSKUListHeader brand={brand} lang={lang} navigation={navigation} />} />
    </div>
  )
}

BrandItemPage.propTypes = {
  brand: PropTypes.object,
  products: PropTypes.array,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  navigation: PropTypes.object
}

export default BrandItemPage
