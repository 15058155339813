import React, { useContext, useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { ProductListTile, PageLink } from '../..'
import { DEFAULT_LIST_PAGE } from '../../../constants'
import { pageURL } from '../../../lib/page'
import { l } from '../../../lib/locale'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'
import { campaigns } from '../../../config/campaigns.json'

const MAX_LIST_ITEMS = 60

const PAGINATION_ACTIVATE_CUTOFF_FROM_PAGE = 10
const PAGINATION_PAGES_TO_SHOW_BEFORE_AFTER = 3

const ProductList = ({ total, products, promos, attributes, attribute_groups, lang, keyPrefix, title, navigation, header,  sortBy, setSortBy, sortMode, setSortMode, itemsPerPage, setItemsPerPage, availableItemsPerPage, showSKU, hideActions }) => {
  const { store } = useContext(context)

  const [prices, setPrices] = useState(promos)

  let banner = null, campaign = null
  if (!hideActions && store && store.app && store.app.cartrules) {
    const cartrules = store.app.cartrules || null

    if (campaigns && campaigns.length > 0 && cartrules && cartrules.length > 0) {
      cartrules.forEach(rule => {
        const c = campaigns.find(c => c.code === rule.code || c.code === rule.display_code)
        if (c) {
          campaign = c
          banner = campaign.banner
        }
      })
    }
  }

  const idxPage = navigation && navigation.page ? navigation.page : DEFAULT_LIST_PAGE

  let qs = null
  if (typeof window !== 'undefined') {
    qs = queryString.parse(window.location.search)
  }

  const totalItemsPerPage = itemsPerPage || MAX_LIST_ITEMS
  const count = total > 0 ? total : products && products.length > 0 ? products.length : 0
  const first = DEFAULT_LIST_PAGE
  const last = Math.ceil(count / totalItemsPerPage)
  const next = idxPage >= last ? last : idxPage + 1
  const previous = idxPage <= DEFAULT_LIST_PAGE ? DEFAULT_LIST_PAGE : idxPage - 1
  let pages = []

  for (let i = 0; i < last; i++) {
    pages.push({
      index: i+1,
      title: i+1
    })
  }

  const paginationOptions = {
    frontEllipsis: {
      idxPage: 1,
      show: false
    },
    backEllipsis: {
      idxPage: 1,
      show: false
    },
  }

  // only activate pagination cutoff when there are a lot of pages to display in the paginator
  if (last > PAGINATION_ACTIVATE_CUTOFF_FROM_PAGE) {
    // remove some pages before the active index
    const pagesBeforeActivePage = (idxPage - PAGINATION_PAGES_TO_SHOW_BEFORE_AFTER) - 1 // index fix

    if (pagesBeforeActivePage > 0) {
      pages = pages.slice(pagesBeforeActivePage)
      paginationOptions.frontEllipsis.show = true
      paginationOptions.frontEllipsis.idxPage = idxPage - 4
    }

    // remove some pages after the active index
    const activePageIndex = pages.findIndex(p => p.index === idxPage)
    const lastPageIndex = (activePageIndex + 1) + PAGINATION_PAGES_TO_SHOW_BEFORE_AFTER

    if (pages.slice(lastPageIndex).length > 0) {
      paginationOptions.backEllipsis.show = true
      paginationOptions.backEllipsis.idxPage = idxPage + 4
    }

    pages = pages.slice(0, lastPageIndex)
  }

  let items = products && products.length > 0 ? products : []

  // fix for when you have already all products loaded on the page
  // if we come from the search we go in batches, we don't load all products
  if (items.length > totalItemsPerPage) {
    const start = 0 + (totalItemsPerPage * (idxPage-1))
    const end = start + totalItemsPerPage

    items = items.slice(start, end)
  }

  useEffect(() => {
    if (store.app.initialized && items && items.length > 0) {
      callAPI(store, store.app.endpoints.promos, { products: items.map(i => i.id_product).join(',') }).then(result => {
        if (result && result._embedded && result._embedded.promos) setPrices(result._embedded.promos)
      })
    }
  }, [store.app.initialized, navigation, products])

  const paginate = (e, page, sort, filters) => {
    if (e) e.preventDefault()
    let suffix = ''
    if (qs) {
      delete qs.page
      suffix = `&${queryString.stringify(qs)}`
    }
    navigate(`?page=${page}${suffix}`)
  }

  const tiles = items.map((product, idx) => {
    const sku = product && product._embedded && product._embedded.skus
      ? product._embedded.skus.find(sku => +sku.id_product_attribute === +product.id_product_attribute)
      : product

    return {
      product: product,
      sku: sku
    }
  })

  if (tiles && tiles.length > 0 && banner && banner.tile && banner.tile.image) {
    if (tiles.length >= 3) {
      tiles.splice(+banner.tile.position - 1, 0, {
        banner: banner.tile
      })
    } else {
      tiles.push({
        banner: banner.tile
      })
    }
  }

  const handleSortBy = (newSortBy) => setSortBy(newSortBy)
  const handleSortMode = () => setSortMode(+!sortMode)

  return (
    <>
      <div className="row product-list-topbar align-items-end">
        {title ? (
          <div className="col-12 col-md-4 mb-1 main-product-title">
            <h2>{title}</h2>
            <span className="article-counter">{count} {l('list-articles', lang)}</span>
          </div>
        ) : ''}

        <div className="col-12 col-md-8 d-flex product-list-topbar-buttons">
          {banner && banner.list && (
            <div className="product-detail-right-banner mb-2">
              {banner.list.image && banner.list.page ? (
                <Link to={pageURL(banner.list.page, lang)}>
                  <img src={banner.list.source[lang.id_lang.toString()]} className="img-fluid" alt={campaign.code} />
                </Link>
              ) : banner.list.image ? (
                <img src={banner.list.source} className="img-fluid" alt={campaign.code} />
              ) : ''}
            </div>
          )}

          {itemsPerPage && (
            <>
              <div className="custom-select-count-wrapper">
                <label htmlFor="custom-select-count">{l('sort-show', lang)}</label>
                <select className="custom-select custom-select-lg custom-select-count ml-2" id="custom-select-count" value={totalItemsPerPage} onChange={(e) => setItemsPerPage(+e.target.value)}>
                  {React.Children.toArray(availableItemsPerPage.map(item => {
                    const label = item === 100000 ? l('sort-show-all', lang) : item
                    return <option value={item}>{label}</option>
                  }))}
                </select>
              </div>
              <div className="custom-buttons-sort-wrapper">
                <button className={`btn-medium-light product-list-topbar-buttons-arrow ${sortBy === 'date_add' && 'product-list-topbar-buttons-active'}`}  onClick={() => handleSortBy('date_add')}>
                  <span>{l('sort-new', lang)}</span>
                </button>

                <button className={`btn-medium-light product-list-topbar-buttons-arrow ${sortBy === 'price' && 'product-list-topbar-buttons-active'}`} onClick={() => handleSortBy('price')}>
                  <span>{l('sort-price', lang)}</span>
                </button>

                <button className={`btn-medium-light product-list-topbar-buttons-arrow ${sortBy === 'quantity_sold' && 'product-list-topbar-buttons-active'}`} onClick={() => handleSortBy('quantity_sold')}>
                  <span>{l('sort-sold', lang)}</span>
                </button>

                <button className="btn-medium-light product-list-topbar-buttons-arrow" onClick={() => handleSortMode()}>
                  <span><i className={`fas ${sortMode ? 'fa-arrow-down' : 'fa-arrow-up'}`}></i></span>
                </button>            
              </div>
            </>
          )}

          {process.env.GATSBY_FEATURE_PRODUCTLIST_FILTER ? (
            <button className="btn-medium-light product-list-topbar-buttons-filter product-list-topbar-buttons-active">
              <span>2 Filters</span>
              <img src="/img/icons/filter_white.png" alt="filter icoon" />
            </button>
          ) : ''}
        </div>
      </div>

      {header}

      <div className="container product-search">
        <div className="row">

          {tiles && tiles.length > 0 ? tiles.map((item, idx) => {
            if (item.product) {
              return (
                <ProductListTile
                  position={+idx + 1}
                  product={item.product}
                  promo={prices ? prices.find(promo => +promo.id_product === +item.product.id_product) : null}
                  sku={item.sku}
                  showSKU={showSKU}
                  attributes={attributes}
                  attribute_groups={attribute_groups}
                  lang={lang}
                  key={`${keyPrefix}-${item.product.id_product}-${item.product.id_product_attribute}`} />
              )
            } else if (item.banner) {
              let banner = (
                <div className="product-photo single">
                  <div className="overlay"></div>
                  <div className="product-labels"></div>
                  <img loading="lazy" className="d-block" width="269px" height="349.7px" src={item.banner.source[lang.id_lang.toString()]} />
                </div>
              )

              if (item.banner.page) {
                banner = <Link to={pageURL(item.banner.page, lang)}>{banner}</Link>
              }

              return (
                <div className="product col-12 col-md-4 col-lg-3 clickable">
                  {banner}
                </div>
              )
            }
          }) : (
            <div className="col-12">
              <div className="alert alert-light">
                <p className="my-0">{l('msg-no-products', lang)}</p>
              </div>
            </div>
          )}

        </div>
      </div>

      {pages && pages.length > 1 && (
        <div>
          <nav aria-label={`${title} navigation`}>
            <ul className="pagination justify-content-center">
              {paginationOptions.frontEllipsis.show && (
                <li className="page-item">
                  <PageLink className="page-link" to={idxPage > 1 ? '..' : './'} aria-label={l('list-first', lang)} onClick={e => paginate(e, first)}>
                    <span aria-hidden="true"><i className="fas fa-chevron-double-left"></i></span>
                    <span className="sr-only">{l('list-first', lang)}</span>
                  </PageLink>
                </li>
              )}

              <li className="page-item">
                <PageLink className="page-link" to={previous > 1 ? `./${previous}` : idxPage > 1 ? '..' : '.'} aria-label={l('list-previous', lang)} onClick={e => paginate(e, previous)}>
                  <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
                  <span className="sr-only">{l('list-previous', lang)}</span>
                </PageLink>
              </li>

              {paginationOptions.frontEllipsis.show && (
                <li className="d-none d-md-block page-item">
                  <PageLink className="page-link" to={`${idxPage > 1 ? '..' : '.'}/${paginationOptions.frontEllipsis.idxPage}`} onClick={e => paginate(e, paginationOptions.frontEllipsis.idxPage)}>
                    ...
                  </PageLink> 
                </li>
              )}

              {pages ? pages.map(p => (
                <li className={`page-item ${p.index === idxPage ? 'active' : 'd-none d-md-block'}`} key={`nav-page-${p.index}`} >
                  <PageLink className="page-link" to={`${idxPage > 1 ? '..' : '.'}/${p.index > 1 ? p.index : ''}`} onClick={e => paginate(e, p.index)}>
                    {p.title}
                  </PageLink>
                </li>
              )) : ''}

              {paginationOptions.backEllipsis.show && (
                <li className="d-none d-md-block page-item">
                  <PageLink className="page-link" to={`${idxPage > 1 ? '..' : '.'}/${paginationOptions.backEllipsis.idxPage}`} onClick={e => paginate(e, paginationOptions.backEllipsis.idxPage)}>
                    ...
                  </PageLink>
                </li>
              )}

              <li className="page-item">
                <PageLink className="page-link" to={`${idxPage > 1 ? '..' : '.'}/${next}`} aria-label={l('list-next', lang)} onClick={e => paginate(e, next)}>
                  <span aria-hidden="true"><i className="fas fa-chevron-right"></i></span>
                  <span className="sr-only">{l('list-next', lang)}</span>
                </PageLink>
              </li>

              {paginationOptions.backEllipsis.show && (
                <li className="page-item">
                  <PageLink className="page-link" to={`${idxPage > 1 ? '..' : '.'}/${last}`} aria-label={l('list-last', lang)} onClick={e => paginate(e, last)}>
                    <span aria-hidden="true"><i className="fas fa-chevron-double-right"></i></span>
                    <span className="sr-only">{l('list-last', lang)}</span>
                  </PageLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      )}
    </>
  )
}

ProductList.propTypes = {
  total: PropTypes.number,
  products: PropTypes.array,
  attributes: PropTypes.array,
  attribute_groups: PropTypes.array,
  lang: PropTypes.object,
  navigation: PropTypes.object,
  keyPrefix: PropTypes.string,
  header: PropTypes.node,
  showSKU: PropTypes.bool,
  hideActions: PropTypes.bool
}

export default ProductList
